$available-height: calc(100vh - #{$body-margin-top-md});
$map-height: calc(#{$available-height} - 91px);
$list-height: calc(#{$available-height} - 91px - 91px - 56px);

.kontrollpanel-panel {
  .kontrollpanel-panel-sub {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: nowrap;
  .avviks-liste {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: $list-height;
  }
  .kontrollpanel-view-velger {
    display: none;
  }
}

.kontrollpanel-header {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  height: 91px;
  max-height: 91px;

  .header-buttons {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .kontrollpanel-btn {
      margin: 0 4px;
      svg {
        margin-right: 13px;
      }
    }
    .kontrollpanel-btn-sok-label {
      display: none;
    }
    .kontrollpanel-btn-sok {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 195px;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .kontrollpanel-sok-input {
        border: none;
        padding-left: 0;
      }
      .input-group-prepend {
        .input-group-text {
          background-color: #fff;
          border: none;
        }
        svg {
          margin-right: 0;
        }
      }
    }

    .btn-secondary-selected {
      background-color: #4d4d4d;
      color: white;
    }
  }
}

.kontrollpanel-filter-row.filter-closed.hide-on-mobile {
  display: none;
}

.kontrollpanel-filter-row.filter-open {
  display: block;
}

.kontrollpanel-filter-row {
  border-bottom: 1px solid $gray-lighter;
  padding: 0 60px 10px 60px;
  height: 65px;
  max-height: 65px;

  .kontrollpanel-filter {
    .kontrollpanel-filter-tittel {
      display: none;
    }
    .dropdown-toggle.btn,
    .dropdown-reset-all.btn {
      color: $gray;
      background-color: transparent;
      line-height: 1;
      padding: 3px 10px 3px 10px;
      margin-right: 10px;
      float: left;
    }

    .dropdown-menu.show {
      max-height: 500px;
      overflow-y: auto;
      z-index: 2000;
      // transform: none !important;
    }
    .dropdown-item [type='checkbox'] {
      width: 17px;
      height: 17px;
      vertical-align: middle;
    }
  }

  .kontrollpanel-filter-tomt {
    font-style: italic;
  }

  .kontrollpanel-filter-aktive-filter {
    margin-top: 10px;
  }

  .kontrollpanel-filter-element {
    margin-right: 5px;
    padding: 0px 5px 1px 10px;
    height: 22px;
    color: white;
    background-color: #4d4d4d;
    float: left;

    .kontrollpanel-filter-element-label {
      padding-right: 15px;
    }

    .kontrollpanel-filter-element-close {
      border: 1px solid #aeaeae;
      color: black;
      background: white;
      border-radius: 30px;
      display: inline-block;
      line-height: 0px;
      padding: 7px 3px;
      vertical-align: unset;
    }
    .kontrollpanel-filter-element-close:before {
      content: '×';
    }
  }
}

.kontrollpanel-liste {
  width: 100%;
  background-color: #fff;
  padding: 0;
  height: 100%;
  border-top: solid 1px $gray-lighter;
  margin-top: -1px;
}
.kontrollpanel-liste > .kontrollpanel-liste-col {
  padding-right: 0;
  padding-left: 0;
}
.kontrollpanel-kart {
  border-left: 1px solid $gray-lighter;
  // height: calc(100vh - 135px);
  width: 100%;
  .leaflet-container {
    width: 100%;
    height: $map-height;
  }
}
.kontrollpanel-panel-avviks-liste {
  width: 100%;
}
.kontrollpanel-avvik-detaljer {
  overflow-y: auto;
  width: 100%;
  height: $map-height;
  border-left: 1px $gray-lighter solid;
}

.kontrollpanel-avvik {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 15px 0 15px;
  border-top: 1px $gray-lighter solid;
}
.hide-on-mobile {
  display: block;

  &.row {
    display: flex;
  }
}

@media (max-width: 769px) {
  .kontrollpanel-kart.hide-on-mobile {
    display: none;
  }
  .kontrollpanel-white-btn {
    background-color: #fff;
  }
}

@media (max-width: 991px) {
  // hide map if blade is open on medium to large screens
  .kontrollpanel-kart.hide-on-medium {
    display: none;
  }

  $available-height: calc(100vh - #{$body-margin-top-lg});
  $map-height: calc(#{$available-height} - 91px);
  $list-height: calc(#{$available-height} - 91px - 60px - 56px);
  .kontrollpanel-kart {
    .leaflet-container {
      height: $map-height;
    }
  }
  .kontrollpanel-avvik-detaljer {
    height: $map-height;
    padding-bottom: 70px;
  }

  .kontrollpanel-panel {
    .avviks-liste {
      height: $list-height;
    }
  }
}

@media (max-width: 769px) {
  $available-height: calc(100% - #{$body-margin-top-md});

  $list-height: 100%;
  .kontrollpanel-kart {
    height: 100%;
    .leaflet-container {
      height: 100%;
    }
  }

  .kontrollpanel-avvik-detaljer {
    height: $available-height;
    margin-bottom: 15px;
  }

  .kontrollpanel-panel {
    .avviks-liste {
      height: $list-height;
    }

    .kontrollpanel-view-velger {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .vinsnings-velger {
        color: $gray;
        svg {
          margin-right: 5px;
        }
      }
      .valgt {
        color: $orange-dark;
      }
    }
  }
  .hide-on-mobile {
    display: none;

    &.row {
      display: none;
    }
  }

  .kontrollpanel-header {
    border-bottom: none;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    height: 62px;
    max-height: 62px;

    .header-buttons {
      order: -1;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      .kontrollpanel-btn {
        margin: 0;
        width: auto;
        svg {
          margin-right: 13px;
        }
      }
      .kontrollpanel-btn-kontroller {
        color: #000;
        background-color: white;
        border: none;
      }
      .kontrollpanel-white-btn {
        border: none;
      }
    }
  }
  // Snarvei søk
  .mobil-kontrollpanel-btn-sok {
    padding: 26px;
    border-top: solid $gray 1px;

    .kontrollpanel-btn-sok-label {
      font-size: 21px;
      font-weight: bold;

      .kontrollpanel-btn-sok {
      }
    }
  }
  // Filter på liten skjerm
  .kontrollpanel-filter-aktive-filter {
    margin: 15px;
    .kontrollpanel-filter-element {
      margin: 5px;
      .kontrollpanel-filter-element-label {
        padding: 7px;
      }
    }
  }
  .kontrollpanel-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    .kontrollpanel-filter-tittel {
      display: block;
      font-size: 21px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      margin: 15px 0;
    }
    .kontrollpanel-filter-filters {
      display: flex;
      flex-direction: column;
      width: 100%;
      .btn-group-sm.dropdown.show {
        .dropdown-toggle::after {
          transform: rotate(-90deg);
          transition: transform 0.35s ease-in-out;
        }
      }
      div[role='menu'] {
        // position: relative !important;
        // transform: translate3d(0, 0, 0) !important;
        width: auto;
        border-radius: 0;
        margin: 15px;
      }
      .btn-group-sm.dropdown {
        position: relative;
        .dropdown-toggle {
          border: none;
          border-bottom: solid 0.5px $gray;
          border-radius: 0;
          height: 48px;
          width: 100%;
          font-size: 16px;
          text-align: left;
        }
        .dropdown-toggle::after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 15px;
          margin-left: 0;
          background-image: url(../../images/glyphicons-pil-ned.svg);
          background-size: 15px 15px;
          height: 15px;
          width: 15px;
          border-top: 0;
          border-right: 0;
          border-bottom: 0;
          border-left: 0;
          transition: transform 0.35s ease-in-out;
        }
      }
    }

    .dropdown-toggle.btn {
      background-color: transparent;
      line-height: 1;
      padding: 3px 10px 3px 10px;
      margin-right: 10px;
      float: left;
    }

    .dropdown-menu.show {
      max-height: 500px;
      overflow-y: auto;
      // transform: none !important;
    }
    .dropdown-item [type='checkbox'] {
      width: 17px;
      height: 17px;
      vertical-align: middle;
    }
  }

  .kontrollpanel-filter-tomt {
    font-style: italic;
  }

  .kontrollpanel-filter-aktive-filter {
    margin-top: 10px;
  }

  .kontrollpanel-filter-element {
    margin-right: 5px;
    padding: 0px 5px 1px 10px;
    height: 22px;
    color: white;
    background-color: #4d4d4d;
    float: left;

    .kontrollpanel-filter-element-label {
      padding-right: 15px;
    }

    .kontrollpanel-filter-element-close {
      border: 1px solid #aeaeae;
      color: black;
      background: white;
      border-radius: 30px;
      display: inline-block;
      line-height: 0px;
      padding: 7px 3px;
      vertical-align: unset;
    }
    .kontrollpanel-filter-element-close:before {
      content: '×';
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  $available-height: calc(100vh - #{$body-margin-top-lg});
  $map-height: calc(#{$available-height} - 91px);
  $list-height: calc(#{$available-height} - 91px - 60px - 56px);
  .kontrollpanel-kart {
    .leaflet-container {
      height: $map-height;
    }
  }
  .kontrollpanel-avvik-detaljer {
    height: $map-height;
  }

  .kontrollpanel-panel {
    .avviks-liste {
      height: $list-height;
    }
  }
}

@media (min-width: $grid-breakpoints-xl) {
  $available-height: calc(100vh - #{$body-margin-top-xl});
  $map-height: calc(#{$available-height} - 91px);
  $list-height: calc(#{$available-height} - 91px - 60px - 56px);
  .kontrollpanel-kart {
    border-left: 1px solid $gray-lighter;
    .leaflet-container {
      height: $map-height;
    }
  }
  .kontrollpanel-avvik-detaljer {
    height: $map-height;
  }

  .kontrollpanel-panel {
    .avviks-liste {
      height: $list-height;
    }
  }
}

span.avvik-status-badge.badge.badge-pill {
  // default
  // color: #656564;
  color: #000;
  padding: 2px 7px 2px 7px;
  border: 1px solid #bfbdbd;
  background-color: #e5e5e5;
}
span.avvik-status-badge.badge.badge-f2c91a82-0bdc-4072-84f7-5a2d15f97ab3.badge-pill,
span.avvik-status-badge.badge.badge-a5205cf3-cdce-452b-91cb-6637fe737305.badge-pill {
  // behandlet, avvik mottatt
  border: 1px solid #6cbd99;
  background-color: #d3f0e3;
}
span.avvik-status-badge.badge.badge-b2b1de1b-4fa5-44a3-a593-4d2294efa3bf.badge-pill {
  // kladd
  border: 1px solid #00548c;
  background-color: #f1f7fb;
}
span.avvik-status-badge.badge.badge-52584cb4-b9b4-4978-be38-d87ccf149efc.badge-pill {
  // ny
  border: 1px solid #bfbdbd;
  background-color: #e5e5e5;
}
span.avvik-status-badge.badge.badge-134ef90e-aee7-4b22-9fd2-9c098c532340.badge-pill {
  // slettet
  border: 1px solid #df7779;
  background-color: #f6d3d0;
}
span.avvik-status-badge.badge.badge-ekstern-innmelder.badge-pill {
  // ekstern innmelder
  background-color: transparent;
}
