.breadcrumbs-wrap {
  margin-top: 67px;
  z-index: 1029;
  background-color: $gray;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 30px;
  .compass-left {
    margin: 1px 4px 0 0;
    display: block;
    padding: 0;
    color: #fff;
    height: 22px;
    .icon-compass {
      width: 18px;
      height: 18px;
    }
  }
  .breadcrumbs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px;
    .crumb {
      .btn {
        color: #fff;
        text-decoration: none;
        font-size: 13px;
        font-weight: bold;
        margin: 2px 6px 2px 6px;
        padding: 0 2px;
        border-radius: 0;
        border-bottom: solid 2px transparent;

        &:hover {
          text-decoration: none;
        }
      }
      .btn.active {
        border-bottom: solid 2px #ffa500;
        // margin: 0 6px 2px 0;
      }
      .btn.disabled {
        color: #fff;
        opacity: 1;
      }
      .crumb-arrow {
        height: 12px;
      }
    }
  }
  .mobile-chevron-right {
    display: none;
  }
}

@media (max-width: $grid-breakpoints-md) {
  .breadcrumbs-wrap.closed {
    .breadcrumbs {
      li:last-child {
        order: -1;
      }
      li:not(:last-child) {
        opacity: 0;
      }
    }
  }
  .breadcrumbs-wrap {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    .breadcrumbs {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .crumb {
        flex-direction: row;
        align-items: center;
        height: 23px;
        transition: opacity 0.35s ease-in-out;
      }
      .crumb:not(:last-child) {
        width: 100%;
        text-align: center;
      }
      .crumb-arrow {
        display: none;
      }
    }
    .mobile-chevron-right {
      display: block;
      padding: 0;
      color: #fff;
      height: 22px;
      svg {
        transform: rotate(-90deg);
        transition: transform 0.55s ease-in-out;
      }
    }
    .mobile-chevron-right.closed {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

@media (max-width: $grid-breakpoints-md) {
  .breadcrumbs-wrap {
    margin-top: calc(#{$body-margin-top-sm} - 39px);
  }
}
@media (min-width: $grid-breakpoints-md) {
  .breadcrumbs-wrap {
    margin-top: calc(#{$body-margin-top-md} - 39px);
  }
}
@media (min-width: $grid-breakpoints-lg) {
  .breadcrumbs-wrap {
    margin-top: calc(#{$body-margin-top-lg} - 39px);
  }
}

@media (min-width: $grid-breakpoints-xl) {
  .breadcrumbs-wrap {
    margin-top: calc(#{$body-margin-top-xl} - 39px);
  }
}
// @media screen and (min-width: 768px) {
// }
