#bekreftet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    max-width: 284px;
    text-align: center;
    margin: 10px 0;
  }
  button {
    margin: 10px 0;
  }
  .takk-ikon {
    width: 140px;
    height: 140px;
  }
  .takk-tekst {
  }
}
#innmelding-bekreftet-map {
  width: 100%;
  height: 100%;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }
}
.innmeldingsbekreftelse {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: nowrap;
  .innmelding-form-input {
    overflow-y: auto;
    width: 100%;
    padding: 25px;
    height: calc(100vh - #{$body-margin-top-xl});
  }
  #innmelding-form-right {
    #innmelding-bekreftet-map {
      #enk-map-input-container {
        width: 100%;
        .leaflet-container {
          width: 100%;
          height: calc(100vh - #{$body-margin-top-xl});
          z-index: 1;
        }
      }
    }
  }
}

@media (max-width: $grid-breakpoints-xl) {
  .innmeldingsbekreftelse {
    .innmelding-form-input {
      height: calc(100vh - #{$body-margin-top-lg});
    }
    #innmelding-form-right {
      #innmelding-bekreftet-map {
        #enk-map-input-container {
          .leaflet-container {
            height: calc(100vh - #{$body-margin-top-lg});
          }
        }
      }
    }
  }
}
