.mappage-container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: nowrap;

  .driftskart-header-container {
    padding: 10px 25px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 6;
    .driftskart-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
    }
  }
  .filterContainer {
    overflow-y: auto;
    width: 100%;
    height: $available-screen-height;
    z-index: 1028;
  }
  .entrepenoerkonrtroll-map {
    width: 100%;
    height: $available-screen-height;
    .leaflet-container {
      width: 100%;
      height: $available-screen-height;
      z-index: 1;
    }
    .leaflet-pane {
      .leaflet-custom-highlighted-pane {
        filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, 0.6));
      }
    }

    .leaflet-popup.avvik-popup {
      font-family: $font-family-base;
      font-size: 0.875rem;
      margin-bottom: 50px;
      .avvik-popup-header {
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
        border-bottom: 1px solid #e9e9e9;
      }

      a.leaflet-popup-close-button {
        top: 13px;
        right: 20px;
        color: #4d4d4d;
        font-size: 22px;
      }

      .avvik-popup-content .avvik-alvorlig-status {
        width: 100%;
        text-align: right;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        label {
          margin: 0;
          padding: 0;
        }
      }

      .avvik-alvorlig-status-indicator {
        border: 1px solid black;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .avvik-alvorlig-status-indicator-alvorlig {
        background-color: $red-dark;
        border-color: $red-dark;
      }
      .avvik-popup-content th {
        vertical-align: top;
      }

      .avvik-popup-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #e9e9e9;
      }
    }

    .leaflet-popup.sporing-popup {
      .leaflet-popup-tip-container {
        display: none;
      }

      .leaflet-popup-content-wrapper {
        //background-color: rgb(0, 84, 140);
        background-color: $blue-dark;
        border: 1px solid #979797;
        color: white;

        .leaflet-popup-content {
          &::before,
          &::after {
            content: '';
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            top: 100%;
            left: 50%;
            margin-left: -10px;
          }
          &::before {
            border-top: 10px solid #979797;
          }
          &::after {
            border-top: 10px solid $blue-dark;
            margin-top: -2px;
            z-index: 1;
          }

          #sporingpopup {
            font-family: $font-family-base;
            font-size: 0.875rem;
            display: flex;

            .sporingpopup-icon {
              padding-left: 56px;
              background-image: url(../../images/snow-white.svg);
              background-repeat: no-repeat;
              background-position: left 0px center;
              background-size: 36px 36px;
            }

            .sporingpopup-content-heading {
              font-size: 1.3em;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .selected-track-open {
    width: 100%;
    height: $available-screen-height;
    overflow-y: scroll;
    .leaflet-container {
      width: 100%;
      height: calc(82vh - 67px);
      z-index: 1;
    }
  }
}

#oversiktkart-overlay {
  position: absolute;
  top: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .content {
    margin: 12px;
  }

  .button-container {
    margin: 12px;
    margin-right: 30px;
  }
  .button-container-mobile {
    margin: 0;
  }
  .close-button {
    position: absolute;
    right: 15px;
    button {
      color: white;
    }
  }
  .close-button-mobile {
    button {
      color: white;
    }
  }
}

#forbruk-spor-container {
  padding: 24px 48px;
  bottom: 0px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;

  table {
    th {
      min-width: 150px;
    }
  }
  h3 {
    margin-bottom: 12px;
  }

  #forbruk-spor {
    display: flex;
    flex-direction: column;
    width: 100%;

    .header-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      h3 {
        margin: 0;
      }

      button {
        display: flex;
        align-items: center;
        padding-right: 0;

        &::after {
          content: '';
          width: 14px;
          height: 14px;
          margin-left: 8px;
          background: url(../../images/glyphicons-208-remove.svg) no-repeat
            no-repeat center center;
          background-size: cover;
        }
      }
    }

    .forbruk-spor-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 24px 0;
      width: 100%;
    }

    div:nth-child(2) {
      margin-top: 0;
    }
  }

  #forbruk-spor-vaer {
    display: flex;
    flex-direction: row;

    .ukedag {
      text-transform: capitalize;
      font-weight: bold;
    }

    .dato-container {
      margin: 0 35px;
    }

    .vaer-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 35px;
        height: auto;
      }
      div {
        text-align: center;
      }
    }
  }

  #forbruk-spor-ressursforbruk {
    width: 100%;

    label {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
    }

    .simple-graph-container {
      margin: 15px 0;

      .simple-graph-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;

        div:first-child {
          font-weight: bold;
        }
      }

      .simple-graph {
        background-color: #cccccc;

        .simple-graph-value {
          background-color: #00548c;
          height: 12px;
        }
      }
    }
  }
}

//below medium screens
@media (max-width: $grid-breakpoints-md) {
  $height: 100%;
  .mappage-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    flex-wrap: nowrap;

    .filterContainer {
      overflow-y: auto;
      width: 100%;
      height: $height;
    }
    .entrepenoerkonrtroll-map {
      width: 100%;
      height: $height;
      .map-container {
        height: $height;
        .leaflet-container {
          width: 100%;
          height: $height;
          z-index: 1;
        }
      }
      .selected-track-open {
        width: 100%;
        height: $height;
        overflow-y: scroll;
        .leaflet-container {
          width: 100%;
          height: calc(82vh - #{$body-margin-top-sm} - 58px);
          z-index: 1;
        }
      }
    }
  }

  #forbruk-spor-container {
    padding: 0px 5px;
    bottom: 0px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-left: none;

    table {
      th {
        min-width: 150px;
      }
    }
    h3 {
      margin-bottom: 12px;
    }

    #forbruk-spor {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 60px;

      .header-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h3 {
          margin: 0;
        }
      }

      .forbruk-spor-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 24px 0;
        width: 100%;
      }

      div:nth-child(2) {
        margin-top: 0;
      }
    }

    #forbruk-spor-vaer {
      margin-top: 20px !important;
      .dato-container {
        margin: 0;
        min-width: 150px;
      }
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  $height: calc(100vh - #{$body-margin-top-lg});
  .mappage-container {
    .filterContainer {
      height: $height;
    }
    .entrepenoerkonrtroll-map {
      height: $height;
      .leaflet-container {
        height: $height;
      }
    }
    .selected-track-open {
      height: $height;
      .leaflet-container {
        height: calc(82vh - #{$body-margin-top-lg});
      }
    }
  }
}
@media (min-width: $grid-breakpoints-xl) {
  $height: calc(100vh - #{$body-margin-top-xl});
  .mappage-container {
    .filterContainer {
      height: $height;
    }
    .entrepenoerkonrtroll-map {
      height: $height;
      .leaflet-container {
        height: $height;
      }
    }
    .selected-track-open {
      height: $height;
      .leaflet-container {
        height: calc(82vh - #{$body-margin-top-xl});
      }
    }
  }
}
