.dropdown-menu.varslinger {
  padding: 0;
}

.nav-link.varslinger {
  width: 100%;
  height: 100%;

  .varslinger-ulest-antall {
    min-height: 18px;
    min-width: 18px;
    border-radius: 50%;
    background-color: #f6a623;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: 11px;
    right: 11px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#varslinger {
  .varsling-liste-container {
    width: 400px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    z-index: 1030;
    position: relative;

    .varsling-liste {
      .varsling-liste-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 10px 8px 10px 20px;
        border-bottom: 1px solid $gray-support;

        span {
          font-weight: bold;
        }

        button.btn-link {
          text-decoration: underline;
          text-transform: none;
        }
      }
      .list-group-item:first-child {
        border-top: none;
      }

      .varsling-liste-items {
        max-height: calc(95vh - 125px);
        overflow: auto;
      }

      .varsling-liste-item {
        display: flex;
        justify-content: space-between;

        &.link {
          cursor: pointer;
        }

        // &.ulest {
        //   background-color: rgba(0, 113, 187, 0.1);
        // }

        .varsling-liste-item-body {
          padding-left: 20px;
          width: 100%;

          .varsling-liste-item-header-text {
            font-weight: bold;
          }

          .varsling-liste-item-text {
            font-weight: normal;
          }

          .varsling-liste-item-time {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .varsling-liste-item-icon {
          padding-left: 56px;
          background-image: url(../../images/envelope.svg);
          background-repeat: no-repeat;
          background-position: left 0px center;
          background-size: 36px 36px;
          border-right: 1px solid $gray-light;

          &.vinterdrift {
            background-image: url(../../images/snow.svg);
          }

          // Renhold
          &.renhold {
            background-image: url(../../images/renhold.svg);
          }

          // Avfall
          &.avfall {
            background-image: url(../../images/avfall.svg);
          }

          // Sluk, kummer og lokk
          &.sluk-kummer-lokk {
            background-image: url(../../images/sluk_og_kummer.svg);
          }

          // Vei og asfalt
          &.vei-asfalt {
            background-image: url(../../images/vei_og_asfalt.svg);
          }

          // Konstruksjoner
          &.konstruksjoner {
            background-image: url(../../images/konstruksjoner.svg);
          }

          // Biler og maskiner
          &.biler-maskiner {
            background-image: url(../../images/biler_og_maskiner.svg);
          }

          // Trær og busker
          &.traer-busker {
            background-image: url(../../images/grontvedlikehold.svg);
          }

          // Miljøavvik
          &.miljoavvik {
            background-image: url(../../images/miljoavvik.svg);
          }
        }
      }
    }
  }
}

.nav.varslinger-mobil {
  margin-left: auto;

  a {
    color: $gray;
  }

  .nav-link.varslinger {
    .varslinger-ulest-antall {
      top: -2px;
      right: 7px;
    }
  }

  .dropdown-menu.varslinger {
    position: fixed;
    left: 0;
    top: 53px;
    right: auto;
    border: none;
  }

  #varslinger {
    .varsling-liste-container {
      width: 100vw;
    }
  }
}
