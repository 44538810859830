.kartlag-header-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 401;
  // width: 250px;
  // height: 40px;
  border-radius: 5px;
  // border: solid 1px #e9e9e9;
  // padding: 9px 14px;
  // background-color: #ffffff;
  // cursor: pointer;
  width: 50px;
  height: 50px;

  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);

  padding: 10px;
  background-color: #ffffff;
  cursor: pointer;
  // .kartlag-header {
  svg {
    width: 30px;
    height: 30px;
  }
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  // .kartlag-header-text {
  // display: none;
  // flex-grow: 2;
  // text-align: left;
  // margin-left: 10px;
  // }
  // }
}
.kartlag-header-caret,
.kartlag-body-caret {
  transition: transform 350ms ease-in-out;
}
.kartlag-header-caret.open,
.kartlag-body-caret.open {
  transform: rotate(0deg);
}
.kartlag-header-caret.closed,
.kartlag-body-caret.closed {
  transform: rotate(-90deg);
}
.kartlag-body-container,
.kartlag-body-enter-done {
  position: absolute;
  z-index: 400;
  width: 250px;
  right: 20px;
  border-radius: 5px;
  border: solid 1px #e9e9e9;
  padding: 9px 14px;
  background-color: #ffffff;
  transition: top 340ms ease-in-out, height 340ms ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
  kartlag-body-container-div {
    height: auto;
    kartlag-body-container-full-height {
      height: 100%;
    }
  }
}
.kartlag-body-container-buttons {
  display: flex;
  justify-content: space-between;

  .kartlag-body-reset-button {
    width: 100%;
    margin-right: 5px;
  }
}
.kartlag-beskrivelse-liste {
  list-style-type: none;
  padding-inline-start: 0;
}

.kartlag-description-group {
  display: flex;
  flex-direction: row;
}

.kartlag-body-container {
  top: 78px;
  height: 70%;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
}

.kartlag-body-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  transition: height 300ms;
  label {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    input[type='checkbox'] {
      margin: 3px;
    }
  }
  button {
    width: 20%;
    color: black;
    &:focus {
      color: $primary;
    }
  }
  ul {
    width: 100%;
  }
}

.kartlag-body-enter {
  top: 34px;
  height: 20px;
}
.kartlag-body-enter-active {
  top: 78px;
  height: 70%;
  transition: top 300ms, height 300ms;
}
.kartlag-body-exit {
  top: 78px;
  height: 70%;
}
.kartlag-body-exit-active {
  height: 20px;
  transition: height 300ms;
}

@media (max-width: $grid-breakpoints-sm) {
  .kartlag-header-container {
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 0;

    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);

    padding: 10px;
    background-color: #ffffff;
    cursor: pointer;
    .kartlag-header {
      svg {
        width: 30px;
        height: 30px;
      }
      .kartlag-header-text {
        display: none;
      }
    }
  }
  .kartlag-header-caret {
    display: none;
  }

  .kartlag-body-container {
    top: 70px;
    right: 10px;
    height: 70%;
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  .kartlag-body-enter {
    top: 70px;
    height: 5px;
  }
  .kartlag-body-enter-active {
    top: 70px;
    height: 70%;
    transition: height 300ms, height 300ms;
  }
  .kartlag-body-exit {
    top: 70px;
    height: 5px;
  }
  .kartlag-body-exit-active {
    height: 5px;
    top: 70px;
    transition: height 300ms;
  }
}
