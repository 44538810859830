.comments > .form-group > label {
  width: 100%;
}
.comments > .form-group > .label > .label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.comments > .form-group > .label > .label-container > h3 {
  display: inline;
}

.comments span.char-counter {
  align-self: center;
}

.comments textarea#comments {
  height: 150px;
}
