@media (min-width: $grid-breakpoints-md) {
  .bym-shortcutbar {
    display: none;
  }
  .menu-component {
    display: none;
  }
  .search-component {
    display: none;
  }
}

@media (max-width: $grid-breakpoints-md) {
  $screen-height: calc(100vh - #{$body-margin-top-sm} - 58px);
  .overlay {
    position: fixed;
    width: 100vw;
    height: $screen-height;
    background-color: #000;
    z-index: 1000;
    opacity: 0.5;
  }

  .overlay-right {
    top: $body-margin-top-sm;
    right: -100vw;
    transition: right ease-out 0.4s;
  }
  .overlay-right.open {
    right: 0;
  }
  .overlay-bottom {
    top: 100vh;
    transition: top ease-out 0.4s;
  }
  .overlay-bottom.open {
    top: 0;
  }
  .bym-shortcutbar {
    border-top: solid 1px $gray;
    .navbar-nav {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      .nav-item {
        position: relative;
        .shorcutbar-btn.active {
          &::after {
            content: '';
            display: inline-block;
            position: absolute;
            height: 15px;
            width: 15px;
            top: 4%;
            right: 4%;
            border-radius: 50%;
            border: solid 2px #fff;
            background-color: $orange-dark;
          }
        }
        .btn {
          color: #4d4d4d;
          width: 55px;
          padding: 6px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            font-size: 10px;
          }
        }
      }
    }
  }
  .menu-component {
    .slider-menu {
      position: fixed;
      width: 80vw;
      height: $screen-height;
      overflow: scroll;
      top: $body-margin-top-sm;
      right: -80vw;
      background-color: #fff;
      z-index: 1000;
      transition: right ease-out 0.4s;
      padding-bottom: 100px;
    }
    .slider-menu.open {
      right: 0;
    }
  }
  .search-component {
    .slider-up-menu {
      position: fixed;
      width: 100%;
      overflow: hidden;
      bottom: -136px;
      height: 136px;
      left: 0;
      right: -80vw;
      background-color: #fff;
      z-index: 1000;
      transition: bottom 0.4s;
      padding-bottom: 100px;
    }
    .open {
      bottom: 50px;
    }
  }
}
