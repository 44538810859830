.kontrollpanel-datovelger,
.kontrollpanel-listheader {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.kontrollpanel-listheader {
  background-color: $gray-lightest;
}

.kontrollpanel-listheader > .dropdowns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  label {
    margin-bottom: 0;
  }
  .dropdown {
    button {
      text-transform: capitalize;
    }
  }
}

.kontrollpanel-listheader > .dropdowns > div {
  margin-left: 15px;
}

.kontrollpanel-kontroller {
  .kontrollpanel-datovelger {
    height: 60px;
    line-height: 60px;
    text-align: center;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    button > span:first-child {
      margin-right: 10px;
    }

    .date-picker {
      width: 150px;
      line-height: 1.5;

      > input {
        background: url(../../images/kalender-ikon.svg) no-repeat 5px 5px;
        background-color: #fff;
        background-position: left 12px center;
        background-size: 20px 20px;
        padding-left: 42px;
        padding-right: 12px;
        text-align: center;
      }
    }
  }

  .list-group {
    width: 100%;
    ul {
      width: 100%;
    }
    .list-group-item {
      &:first-child {
        border-top: none;
      }

      &.selected,
      &:hover {
        background-color: rgba(0, 113, 187, 0.1);
      }

      &.kladd {
        background-color: #fffeed;
      }
    }
  }

  .kontrollpanel-listheader {
    height: 56px;
    line-height: 56px;
    background-color: lightgray;
    line-height: 1.5 !important;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.item-count {
        flex-grow: 1;
        font-weight: bold;
      }

      &.dropdowns {
        display: flex;

        label {
          font-weight: bold;
        }

        .dropdown {
          margin-left: 10px;
        }
      }
    }
  }
}

.avvik-item {
  display: flex;
  width: 100%;
  cursor: pointer;

  a {
    text-decoration: underline;
  }

  div.avvik-item-icon {
    padding-left: 56px;
    // background-image: url(./images/snow.svg);
    background-repeat: no-repeat;
    background-position: left 0px center;
    background-size: 36px 36px;
    border-right: 1px solid $gray-light;
    .alvorlig-indikator {
    }
  }
  div.avvik-item-icon.botgrunnlag::after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 15px;
    width: 15px;
    top: 35px;
    left: 45px;
    border-radius: 50%;
    border: solid 2px #fff;
    background-color: $red;
  }
  // Vinterdrift
  div.avvik-item-icon.vinterdrift {
    background-image: url(../../images/snow.svg);
  }

  // Renhold
  div.avvik-item-icon.renhold {
    background-image: url(../../images/renhold.svg);
  }

  // Avfall
  div.avvik-item-icon.avfall {
    background-image: url(../../images/avfall.svg);
  }

  // Sluk, kummer og lokk
  div.avvik-item-icon.sluk-kummer-lokk {
    background-image: url(../../images/sluk_og_kummer.svg);
  }

  // Vei og asfalt
  div.avvik-item-icon.vei-asfalt {
    background-image: url(../../images/vei_og_asfalt.svg);
  }

  // Konstruksjoner
  div.avvik-item-icon.konstruksjoner {
    background-image: url(../../images/konstruksjoner.svg);
  }

  // Biler og maskiner
  div.avvik-item-icon.biler-maskiner {
    background-image: url(../../images/biler_og_maskiner.svg);
  }

  // Trær og busker
  div.avvik-item-icon.traer-busker {
    background-image: url(../../images/grontvedlikehold.svg);
  }

  // Miljøavvik
  div.avvik-item-icon.miljoavvik {
    background-image: url(../../images/miljoavvik.svg);
  }

  div.avvik-item-icon.default {
    // background-image: url(./images/miljoavvik.svg);
  }

  div.avvik-item-body {
    padding-left: 20px;
    width: 100%;

    .avvik-item-header {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      .avvik-item-header-text {
        font-weight: bold;

        &.kladd {
          font-style: italic;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        right: 20px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        background: url(../../images/glyphicons-pil-ned.svg) no-repeat no-repeat
          center center;
        background-size: cover;
      }
    }

    .avvik-item-time-location {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      span {
        white-space: nowrap;
      }
      .avvik-item-location {
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .avvik-item-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;

      > div {
        &:last-child {
          text-align: right;
        }
      }

      > label.form-check-label {
        font-weight: bold;
      }
    }
  }
}
@media (max-width: $grid-breakpoints-sm) {
  .list-group-item {
    padding: 6px 5px;
    display: flex;

    a {
      text-decoration: underline;
    }

    div.avvik-item-icon {
      padding-left: 36px;
      background-position: left 0px center;
      background-size: 36px 36px;
    }
    div.avvik-item-icon.botgrunnlag::after {
      top: 25px;
      left: 30px;
    }
    div.avvik-item-body {
      padding-left: 10px;
      width: 100%;
    }
  }
}
