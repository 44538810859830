html.monitor-mode .dashboard-wrapper {
  background-color: black !important;
  color: white;
}

html.monitor-mode .recharts-text {
  stroke: white;
  stroke-width: 1;
}

html.monitor-mode p.recharts-tooltip-label {
  color: black;
}

.dashboard-wrapper {
  padding: 25px;
}

.meldingsstatistikk-container {
  .antall-meldinger-container {
    text-align: center;
  }

  .totalt-antall {
    .header {
      font-size: 24px;
      font-weight: bold;
    }
    .main {
      font-size: 50px;
    }
    .footer {
      font-size: 20px;
    }
  }

  .manedsfordeling {
    display: flex;
    justify-content: center;

    .antall-meldinger-container {
      margin: 20px 30px 20px 30px;
    }

    .denne-maneden,
    .forrige-maned {
      .header {
        font-size: 16px;
      }
      .main {
        font-size: 35px;
      }
      .footer {
        font-size: 14px;
      }
    }
  }

  .avvistfordeling {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .avvist-ganger-og-antall,
    .avvist-antall-pr-meldingstype {
      margin: 20px 30px 20px 30px;
    }

    .avvistfordeling-header {
      font-weight: bold;
      font-size: 16px;
    }

    .avvist-antall-pr-meldingstype td {
      padding-right: 10px;
    }
  }

  .avvist-fordeling-kakediagram,
  .endringer-fordeling-kakediagram {
    text-align: center;
    padding-top: 30px;

    .kakediagram-header {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .diagram-container .recharts-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
