//Mobile-screens
@media (max-width: $grid-breakpoints-sm) {
  .desktop-only {
    display: none;
  }
}

//Larger screens
@media (min-width: $grid-breakpoints-sm) {
  .mobile-only {
    display: none;
  }
}

//below medium screens
@media (max-width: $grid-breakpoints-md) {
  .medium-plus-only {
    display: none;
  }
}

//Larger then medium screens
@media (min-width: $grid-breakpoints-md) {
  .medium-minus-only {
    display: none;
  }
}

#page-container {
  margin-top: $body-margin-top-sm;
  width: 100%;
}

$available-screen-height: calc(100vh - #{$body-margin-top-sm});

@media (max-width: $grid-breakpoints-xl) {
  #page-container {
    margin-top: $body-margin-top-lg;
    width: 100%;
  }
}
@media (min-width: $grid-breakpoints-xl) {
  #page-container {
    margin-top: $body-margin-top-xl;
    width: 100%;
  }
}

button.btn-primary:hover {
  background-color: #5b86a1;
}
