/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest-input {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;

  .invalid {
    border-color: $danger;
    background-color: $red-lightest;
    color: $danger;
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .geosuggest-form-control {
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }

  .geosuggest-soketreff-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 1px solid #ced4da;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;

    .geosuggest-soketreff-item {
      font-size: 18px;
      font-size: 1rem;
      padding: 0.5em 0.65em;
      cursor: pointer;
    }

    .geosuggest-soketreff-item:hover,
    .geosuggest-soketreff-item:focus {
      background: #f5f5f5;
    }
  }

  .geosuggest-my-location-button {
    margin-left: 5px;
    padding: 4px 12px;
    height: 35px;
    align-self: flex-end;

    img {
      width: 21px;
      height: 21px;
    }
  }
}
.invalid-geo-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}
