.innmelding-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: nowrap;
  .datetime-input-container {
    .form-group {
      span {
        font-size: 16px;
      }
    }
  }
  .innmelding-form-support-text,
  label {
    font-size: 16px;
  }
  .innmelding-form-input {
    overflow-y: auto;
    width: 100%;
    padding: 25px;
    height: calc(100vh - 64px - 39px);

    .input-required-desc {
      display: flex;
      flex-direction: row;
      align-items: center;
      .input-required-start {
        margin: 0 3px;
        font-size: 31px;
      }
    }

    .innmelding-form-header {
      margin-top: 39px;
    }

    #innmelding-form-hva {
      margin-top: 10px;
    }

    #innmelding-form-angi-sted {
      margin-top: 10px;
      #enk-map-input-container {
        display: none;
      }
    }

    #innmelding-form-angi-img {
      margin-top: 10px;
    }
    #innmelding-form-angi-comment {
      margin-top: 10px;
    }
  }
  #innmelding-form-map {
    #enk-map-input-container {
      width: 100%;
      .leaflet-container {
        width: 100%;
        height: calc(100vh - 64px - 39px);
        z-index: 1;
      }
    }
  }

  .innmelding-form-footer-spinner {
    height: 20px;
  }

  .innmelding-form-footer-buttons {
    .btn {
      margin-right: 5px;
    }
    .btn-link {
      display: block;
      padding-left: 0;
      margin-top: 10px;
      color: #4a4a4a;
      text-decoration: none;
      font-size: 14px;
      svg {
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: 991px) {
  .innmelding-form {
    .innmelding-form-input {
      #innmelding-form-angi-sted {
        #enk-map-input-container {
          margin: 15px 0;
          width: 100%;
          display: block;
          height: 400px;
          .leaflet-container {
            height: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  #innmelding-form-map {
    #enk-map-input-container {
      position: relative;
      width: 100%;
      height: 300px;
      top: 0;

      .leaflet-container {
        width: 100%;
        height: 300px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .innmelding-form {
    .innmelding-form-input {
      height: calc(100vh - 52.94px - 39px);
    }
    #innmelding-form-map {
      #enk-map-input-container {
        .leaflet-container {
          height: calc(100vh - 52.94px - 39px);
        }
      }
    }
  }
}
