.isdirty-btn {
  position: static;
  width: 46px;
  height: 46px;
  border-radius: 0;
  &.isdirty-loading {
    svg {
      animation: isdirty-spin 1000ms infinite;
    }
  }
  &.isdirty-dirty {
  }
  &.isdirty-success {
  }
}

@keyframes isdirty-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//below medium screens
@media (max-width: $grid-breakpoints-md) {
  .isdirty-btn {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 300;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    padding: 10px;
    cursor: pointer;
    transition: all 400ms ease-out;
  }
  .kartdata-isdirty-mobile {
    &.isdirty-mobile-filter-open {
      .isdirty-btn {
        top: 113.94px;
        right: calc(80vw + 20px);
        z-index: 1001;
      }
    }
    &.isdirty-mobile-filter-closed {
      .isdirty-btn {
        top: 170px;
        right: 20px;
      }
    }
  }
}

@media (max-width: 576px) {
  .isdirty-btn {
    width: 50px;
    height: 50px;
    border-radius: 0;
    transition: all 400ms ease-out;
    z-index: 300;
  }
  .kartdata-isdirty-mobile {
    &.isdirty-mobile-filter-open {
      .isdirty-btn {
        top: 100px;
        right: calc(80vw + 10px);
        z-index: 1001;
      }
    }
    &.isdirty-mobile-filter-closed {
      .isdirty-btn {
        top: 165px;
        right: 10px;
      }
    }
  }
}

//Larger then medium screens
@media (min-width: $grid-breakpoints-md) {
}
