.tabelldiagram-table {
  width: 100%;
  table-layout: fixed;

  th {
    vertical-align: bottom;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }
  td,
  th {
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
  }

  thead {
    tr {
      height: 25px;
      background-color: #034b45;

      th {
        font-weight: bold;
        color: white;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }

    td:first-child {
      text-align: left;
      height: 20px;
      background-color: #c7f6c9;
    }
  }

  tfoot td {
    font-weight: bold;
    border-top: 1px solid black;
    border-bottom: 2px double black;
    border-bottom-style: double;
  }
}
