.app {
  background-color: white;
  margin: 0 auto;
}

/* headless dark mode */
html.monitor-mode,
html.monitor-mode body {
  background-color: black !important;
  color: white;
}
html.monitor-mode nav {
  display: none !important;
}
html.monitor-mode #page-container {
  margin-top: 0;
}

h2 {
  color: darkgray;
}

.site-container {
  display: block;
}
