.kontrollpanel-panel-sub,
.diagram-container-datovelgere {
  .periodevelger-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    overflow: hidden;
    .btn {
      &:hover {
        color: $gray;
      }
    }
    .btn[disabled] {
      cursor: not-allowed;
    }
    .periodevelger-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .periodevelger-main-button {
      font-size: 20px;
      font-weight: bold;
      .kalender-ikon {
        width: 21px;
        height: 20px;
        margin: 0 4px;
      }
      .pil-ned-ikon {
        width: 13px;
        height: 13px;
        margin: 0 4px;
      }
    }
    .periodevelger-secondary-button {
      font-size: 14px;
      font-weight: normal;
      overflow: hidden;
      color: $gray;
      span {
        height: 16px;
      }
      svg {
        width: 8px;
        height: 16px;
        margin: 4px 4px 0 4px;
      }
    }
  }
}
.periodevelger-popover {
  .popover {
    z-index: 110000;
    max-width: 343px;
    .popover-inner {
      .popover-body {
        padding: 0.5rem 0.1rem;
      }
    }
  }
  .periodevelger-knapp-gruppe {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .periodevelger-knapp {
      flex-grow: 1;
      background-color: rgba(240, 240, 240, 0.83);
      color: #000;
      border-radius: 0;
      border: solid 1px #979797;
    }
    .btn.active {
      background-color: #4a4a4a;
      color: #fff;
    }
  }
  .periodevelger-kalender.react-calendar {
    border: none;
    width: 343px;

    .react-calendar__month-view__days__day--weekend,
    .react-calendar__month-view__days__day--neighboringMonth {
      color: inherit;
    }

    .react-calendar__month-view__days,
    .react-calendar__year-view__months,
    .react-calendar__decade-view__years {
      // .react-calendar__tile--hover {
      //   background-color: $gray-lightest;
      // }
      .react-calendar__tile--now {
        background-color: #006edc;
        color: #fff;
      }
      .react-calendar__tile--active {
        background-color: #9b9b9b;
        color: #fff;
      }
      .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd {
        background-color: #4d4d4d;
        color: #fff;
      }
      .react-calendar__tile:disabled {
        background-color: #fff;
        color: #9b9b9b;
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      abbr[title] {
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .react-calendar__navigation {
      margin-bottom: 0;
      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }
      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button,
      .react-calendar__navigation__next-button {
        font-size: 23px;
        font-weight: bold;
      }
      .react-calendar__navigation__label {
        font-size: 14px;
        font-weight: bold;
      }
      button[disabled].react-calendar__navigation__label {
        background-color: #fff;
        color: #212121;
      }
    }
  }
}

@media (max-width: 1370px) {
  .periodevelger-container {
    .periodevelger-button {
    }
    .periodevelger-main-button {
      .kalender-ikon {
        width: 21px;
        height: 20px;
        margin: 0 4px;
      }
      .pil-ned-ikon {
        width: 13px;
        height: 13px;
        margin: 0 4px;
      }
    }
    .periodevelger-secondary-button {
      span {
        display: none;
      }
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}
