.entrepenoerkontroll-filter {
  padding: 0 25px;
}

.entrepenoerkontroll-filter-row {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 10px;
  width: 100%;
}

.entrepenoerkontroll-filter-row-section {
  button.collapsible {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      right: 0;
      margin-right: 2px;
      transition: transform 0.35s;
    }

    &::after {
      background: url(../../images/glyphicons-pil-ned.svg) no-repeat no-repeat
        center center;
      background-size: cover;
    }

    &.closed::after {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  button.btn-primary {
    margin-bottom: 12px;
  }

  &.entrepenoerkontroll-filter-row-section-collapse {
    border-top: 1px solid lightgray;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 12px;

    > div {
      &:not(:empty) {
        padding-top: 12px;
      }
    }
  }

  &.entrepenoerkontroll-filter-datetime {
    .periodevelger-time {
      margin: 15px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .periodevelger-time-input-group {
        padding: 7px;
        display: flex;
        flex-direction: row;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        &:first-child {
          margin-right: 7px;
        }
        &:last-child {
          margin-left: 7px;
        }
        .time-form-control {
          width: 100%;
          border: none;
        }
        .klokke-ikon-container {
          width: 44px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          svg {
            width: 21px;
            height: 21px;
          }
          background-color: #e5e5e5;
          padding: 7px;
          margin: -7px;
          border-left: 1px solid #ced4da;
        }
      }
    }

    .periodevelger-container {
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .periodevelger-button {
        font-size: 15px;
        font-weight: normal;
      }
      .periodevelger-secondary-button {
        display: none;
      }
      .periodevelger-main-button {
        padding: 7px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        .periodevelger-pil-ned {
          display: none;
        }
        .kalender-ikon-container {
          width: 37px;
          background-color: #e5e5e5;
          padding: 7px;
          margin: -7px;
          border-left: 1px solid #ced4da;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          svg {
            width: 21px;
            height: 21px;
            color: #495057;
          }
        }
      }
    }
  }

  .collapsible-omrade-group {
    .form-check {
      display: block;
    }
  }

  .btn-link {
    padding: 0;
    text-align: left;
    border: none;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.2;
    color: #4d4d4d;
    padding-top: 12px;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }

  .form-check {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .checkbox-legend {
      width: 24px;

      &.arbeid {
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.5);
        border: solid 0.5px #ffffff;
        height: 16px;
        margin: 2px;
      }

      &.avviktype {
        // height: 21px;
        &.id-ce8b4b51-6a91-47a5-9aa8-993f0ef5da1b {
          background: url(../../images/map_pin_bymelding_fra_publikum_ikke_behandlet.svg)
            no-repeat center center;
          background-size: contain;
        }
        &.id-080693ae-e078-4e41-9045-39eabf96f2f6 {
          background: url(../../images/map_pin_avvik_fra_kontrollør.svg)
            no-repeat center center;
          background-size: contain;
        }
        &.id-c9d0d4ec-bcd2-4ffb-bfda-ff958b54129b {
          background: url(../../images/map_pin_varsel_fra_entreprenør.svg)
            no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
}

@media (max-width: $grid-breakpoints-md) {
  .entrepenoerkontroll-filter {
    .entrepenoerkonrtroll-map {
      width: 100%;
      height: calc(100vh - 60.3px);
      .leaflet-container {
        width: 100%;
        height: calc(100vh - 60.3px);
        z-index: 1;
      }
    }
  }
}
