// height: 100%;
// background-color: $gray-lightest;
// border: none;

.antall-kontroller {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .invalid-datetime-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $danger;
  }
  .antall-kontroller-kalender,
  .antall-kontroller-antall {
    .invalid input {
      border-color: $danger;
      color: $danger;
    }
  }
  .antall-kontroller-body {
    .antall-kontroller-body-form {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
      .antall-kontroller-kalender,
      .antall-kontroller-antall {
        width: 35%;
        label,
        .antall-kontroller-kalender {
          width: 100%;
        }
      }
    }
  }
  .antall-kontroller-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
.antall-kontroller-plus-icon {
  display: none;
}

@media (max-width: 769px) {
  .antall-kontroller-plus-icon {
    display: inline;
  }
}

@media (max-width: 575px) {
  // .antall-kontroller {
  //   margin-top: 50px;
  // }
  .antall-kontroller {
    .antall-kontroller-body {
      .antall-kontroller-body-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .antall-kontroller-kalender,
        .antall-kontroller-antall {
          width: 100%;
        }
      }
    }
  }
}
