.diagram-container-diagramtype-knapper {
  align-self: flex-end !important;
  img {
    height: 20px;
  }
  button.btn {
    padding: 5px;
    margin-right: 10px;
    background-color: transparent;
    border: none;
  }
}
.diagram-container-datovelgere {
  display: flex;
  justify-content: flex-end;

  .periodevelger-container {
    height: 39px;
    width: auto;
    float: right;
    .periodevelger-main-button {
      min-width: 192px;
    }
    .kalender-ikon-container {
      margin-left: 10px !important;
    }
  }
  .periodevelger-time {
    margin-left: 10px;
    max-width: 130px;
  }
  table {
    float: right;
  }

  .periodevelger-container {
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    .periodevelger-button {
      font-size: 15px;
      font-weight: normal;
    }
    .periodevelger-secondary-button {
      display: none;
    }
    .periodevelger-main-button {
      padding: 6px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      .periodevelger-pil-ned {
        display: none;
      }
      .kalender-ikon-container {
        width: 37px;
        background-color: #e5e5e5;
        padding: 7px;
        margin: -7px;
        border-left: 1px solid #ced4da;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        svg {
          width: 21px;
          height: 21px;
          color: #495057;
        }
      }
    }
  }

  .periodevelger-time {
    //margin: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .periodevelger-time-input-group {
      padding: 7px;
      display: flex;
      flex-direction: row;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      &:first-child {
        //margin-right: 7px;
      }
      &:last-child {
        display: none;
      }
      .time-form-control {
        width: 100%;
        border: none;
      }
      .klokke-ikon-container {
        width: 44px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        svg {
          width: 21px;
          height: 21px;
        }
        background-color: #e5e5e5;
        padding: 7px;
        margin: -7px;
        border-left: 1px solid #ced4da;
      }
    }
  }

  .statistikk-datovelger .rdtPicker {
    margin-left: -100px;
    margin-top: 35px;
  }

  .statistikk-tidspunktvelger .rdtPicker {
    margin-left: 10px;
    margin-top: 35px;
  }

  table th {
    font-size: 10px;
    padding-left: 10px;
  }

  input.form-control {
    width: 130px;
    float: left;
    margin-left: 10px;
  }

  .statistikk-datovelger input[type='text'] {
    background: url(../../images/kalender-ikon.svg) no-repeat 5px 5px;
    background-color: #fff;
    background-position: right 5px center;
    background-size: 20px 20px;
  }

  .statistikk-tidspunktvelger {
    .rdtPicker {
      width: auto;
    }

    input[type='text'] {
      background: url(../../images/klokke-ikon.svg) no-repeat 5px 5px;
      background-color: #fff;
      background-position: right 5px center;
      background-size: 20px 20px;
    }
  }
}

.diagram-container-header-tittel {
  float: left;
}
.diagram-container-header-meny {
  float: right;

  img {
    height: 20px;
  }

  button.btn {
    padding: 5px;
    background-color: transparent;
    border: none;
  }

  .dropdown-menu {
    background-color: #2a2859;
    padding: 0;
  }

  .dropdown-item {
    //padding-left: 0;
    padding: 5px;
    color: white;
    border-bottom: 1px solid white;
  }

  .dropdown-item:hover {
    background-color: #ddd;
  }
}

.diagram-container-diagram {
  height: 500px;
  div.no-data {
    text-align: center;
    padding-top: 100px;
    background-color: #d9d9d9;
  }
}
