.searchable-dropdown-list {
  label {
    display: inline;
  }
  .react-select-container {
    .react-select__control {
      font-family: $input-font-family;
      @include font-size($input-font-size);
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      color: $input-color;
      background-color: $input-bg;
      background-clip: padding-box;
      border: $input-border-width solid $input-border-color;
      // Customize the `:focus` state to imitate native WebKit styles.
      @include form-control-focus();

      .react-select__value-container {
        .react-select__placeholder {
          color: $input-color-placeholder;
        }
      }
      .react-select__indicators {
        svg {
          transform: rotate(-90deg);
          transition: transform 0.35s ease-in-out;
        }
      }
    }
    .react-select__control--menu-is-open {
      .react-select__indicators {
        svg {
          transform: rotate(0deg);
        }
      }
    }

    .react-select__menu {
      z-index: 3050;
      .react-select__menu-list {
        .react-select__option {
        }
      }
    }
  }
  .react-select-container.invalid {
    .react-select__control {
      border-color: $danger;
      color: $danger;
    }
  }

  .dropDown-input.invalid > input {
    border-color: $danger;
    color: $danger;
  }
  .invalid-dropDown-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $danger;
  }
}
