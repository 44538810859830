.image-uploader {
  div.label {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-top: 39px;
  }

  span.image-counter {
    align-self: center;
    min-width: 30px;
  }

  input[type='file'] {
    display: none;
  }

  .image-uploader-button {
    width: 100%;
  }

  .image-uploader-preview {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 104px;
  }

  .image-container {
    position: relative;
    width: 81px;
    height: 82px;
    border: 1px gray solid;
    margin: 11px;
  }
  .image-container img {
    width: 100%;
    height: 100%;
  }

  .image-container button.close {
    position: absolute;
    top: -11px;
    right: -11px;
    opacity: 1;
    background-color: #fff;
    color: gray;
    border: 1px gray solid;
    width: 22px;
    height: 22px;
    padding: 0px;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
  }

  .image-container button.close:hover {
    opacity: 1;
    color: #000;
  }

  .image-container button.close span {
    position: absolute;
    top: -2px;
    left: 4px;
  }
}
.image-uploader.invalid {
  .image-uploader-preview {
    border: 1px solid $danger;
    border-radius: 4px;
  }
}
.image-uploader-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}
