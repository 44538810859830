.datetime-input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;

  h4 {
    font-size: 21px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
  }
  .form-group {
    width: 100%;
  }
  .form-group:first-child {
    padding-right: 12px;
  }
  .form-group:last-child {
    padding-left: 12px;
  }

  label {
    width: 100%;
  }

  .datetime-input > input {
    margin-top: 0px;
    /* border: 0.5pt solid hsl(0, 0%, 80%); */
    border-radius: 4px;
    padding: 7px 5px;
    margin: 2px;
  }

  .datetime-input-date > input {
    background: url(../../images/kalender-ikon.svg) no-repeat 5px 5px;
    background-color: #fff;
    background-position: right 5px center;
    background-size: 20px 20px;
  }

  .datetime-input-time > input {
    background: url(../../images/klokke-ikon.svg) no-repeat 5px 5px;
    background-color: #fff;
    background-position: right 5px center;
    background-size: 20px 20px;
  }

  .datetime-input.invalid input {
    border-color: $danger;
    color: $danger;
  }
  .invalid-datetime-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $danger;
  }
  .datetime-input.datetime-input-time {
    .rdtPicker {
      width: 100%;
    }
  }
  .datetime-input.datetime-input-date {
    .rdtPicker {
      width: 150%;
    }
  }
}
