// $body-bg: red;
// $body-color: blue;

$font-family-sans-serif: 'Lato';
$font-family-base: 'Lato';
$enable-gradients: false;
$enable-rounded: true;
$enable-transitions: true;
$spacer: 0.25rem;
$enable-shadows: false;

$gray: #4d4d4d;
$gray-support: #717171;
$gray-light: #cccccc;
$gray-lighter: #e5e5e5;
$gray-lightest: #f3f3f3;

$blue-dark: #00548c;
$blue: #0071bb;
$blue-light: #b3d5eb;
$blue-lighter: #d9eaf5;
$blue-lightest: #f1f7fb;

$green-dark: #198656;
$green: #22b473;
$green-light: #b2e5ce;
$green-lighter: #d9f6ed;
$green-lightest: #e8f7f1;

$orange-dark: #f29f04;
$orange: #fea90a;
$orange-light: #ffe1aa;
$orange-lighter: #ffeece;
$orange-lightest: #fef6e6;

$red-dark: #c3190c;
$red: #d32316;
$red-light: #f0b2ae;
$red-lighter: #f6d3d0;
$red-lightest: #fae8e7;

$bymelding-header-color: #d8d8d8;

$primary: $blue-dark;
$secondary: $gray-lighter;
$success: $green-dark;
$info: $blue-dark;
$warning: $orange-dark;
$danger: $red-dark;

$light: $gray-lighter;
$dark: $gray;

$headings-color: #4d4d4d;
$headings-font-weight: bold;

$input-color-placeholder: $gray-support;

$input-group-addon-bg: $secondary;

$input-focus-border-color: $blue;
$input-focus-box-shadow: none;
$input-border-width: 1px;

$font-size-base: 14;

$navbar-height: 64px;
$navbar-brand-height: 64px;
$navbar-default-bg: $bymelding-header-color;
$navbar-margin-bottom: 0;
$navbar-padding-y: $spacer / 2;
$navbar-padding-x: $spacer;
$navbar-brand-font-size: 24;
$navbar-brand-padding-y: 5px;
$nav-tabs-border-color: #4d4c4d;

$nav-divider-color: #4d4c4d;

h1,
h2,
h3,
h4 {
  color: $headings-color !important;
}

h1 {
  font-size: 40px !important;
}
h2 {
  font-size: 30px !important;
}
h3 {
  font-size: 21px !important;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 770px,
  lg: 992px,
  xl: 1200px,
);

$body-margin-top-sm: calc(54.94px + 39px);
$body-margin-top-md: calc(54.938px + 39px);
$body-margin-top-lg: calc(52.94px + 39px);
$body-margin-top-xl: calc(64px + 39px);

$grid-gutter-width: 0;
$grid-breakpoints-xl: map-get($grid-breakpoints, 'xl');
$grid-breakpoints-lg: map-get($grid-breakpoints, 'lg');
$grid-breakpoints-md: map-get($grid-breakpoints, 'md');
$grid-breakpoints-sm: map-get($grid-breakpoints, 'sm');

// $body-margin-top-sm: 60.125px;
// $body-margin-top-lg: 64px;
// $body-margin-top-xl: 67px;

// Required
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

// Optional
@import 'node_modules/bootstrap/scss/reboot';
// @import "node_modules/bootstrap/scss/root";
@import 'node_modules/bootstrap/scss/type';
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
// @import "node_modules/bootstrap/scss/button-group";
@import 'node_modules/bootstrap/scss/input-group';
// @import "node_modules/bootstrap/scss/custom-forms";
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
@import 'node_modules/bootstrap/scss/badge';
// @import "node_modules/bootstrap/scss/jumbotron";
@import 'node_modules/bootstrap/scss/alert';
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/media";
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
// @import "node_modules/bootstrap/scss/toasts";
@import 'node_modules/bootstrap/scss/modal';
// @import "node_modules/bootstrap/scss/tooltip";
@import 'node_modules/bootstrap/scss/popover';
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/spinners";
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/print';
