.innmelding-form-mobil {
  height: 100%;
  .innmelding-form-step {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 35px 16px 20px 16px;
    h2 {
      text-align: center;
      width: 100%;
      padding: 0 20%;
      font-size: 30px;
      font-weight: bold;
    }
    h3 {
      margin-top: 20px;
      width: 100%;
      text-align: left;
      font-weight: bold;
    }
    .innmelding-form-mobil-footer {
      .innmelding-form-mobil-footer-spinner {
        height: 20px;
      }

      .innmelding-form-mobil-footer-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .btn {
          font-size: 16px;
          font-weight: bold;
        }
        .btn-primary {
        }
        .btn-link {
          color: #4a4a4a;
          text-decoration: none;
          font-size: 16px;
          padding-left: 0;
          svg {
            margin-right: 12px;
          }
        }
        .btn-secondary {
          border: solid 1px #4a4a4a;
          background-color: #fff;
        }
      }
    }
  }

  .innmelding-form-step-when {
    min-width: 100%;

    #innmelding-form-hva {
      min-width: 100%;
    }
  }

  .innmelding-form-step-where {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    #innmelding-form-map-loading-overlay {
      background-color: #000;
      opacity: 0.5;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: calc(60vh - 60px);
      z-index: 2;
      div {
        margin: auto;
        vertical-align: middle;
      }
    }
    #innmelding-form-map {
      display: block;
      width: 100%;
      height: calc(60vh - 60px);
      #enk-map-input-container {
        display: block;
        width: 100%;
        height: calc(60vh - 60px);
        .leaflet-container {
          width: 100%;
          height: calc(60vh - 60px);
          z-index: 1;
        }
      }
    }
    .innmelding-form-mobil-step-where-second {
      height: 100%;
      padding: 30px 16px 20px 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      #innmelding-form-angi-sted {
        width: 100%;
        font-size: 16px;
      }
    }
  }
  .innmelding-form-step-comment {
    .comments {
      width: 100%;
      .label-container {
        font-size: 19px;
        .label-container-label {
          font-weight: bold;
        }
      }
    }
  }
}
