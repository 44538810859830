.searchable-dropdown-list .single-value.kategori-option-img,
.searchable-dropdown-list .option.kategori-option-img {
  background-repeat: no-repeat;
  background-position: left 0px center;
  background-size: 20px 20px;
  padding-left: 30px;
}

.searchable-dropdown-list .option {
  padding-left: 40px;
}

.searchable-dropdown-list .option.kategori-option-img {
  background-position: left 10px center;
  padding-left: 40px;
}

.searchable-dropdown-list .kategori-option-vinterdrift {
  background-image: url(../../images/snow.svg);
}

.searchable-dropdown-list .kategori-option-avfall {
  background-image: url(../../images/kalender-ikon.svg);
}

.searchable-dropdown-list .kategori-option-gatelys {
  background-image: url(../../images/klokke-ikon.svg);
}

.searchable-dropdown-list .kategori-option-traer {
  background-image: url(../../images/marker-icon.png);
}
