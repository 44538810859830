.statistikkPage-dashboard {
  background-color: #000;
  
  
  .statistikkPage-dashboard-chart-container {
    height: 85vh;
    width: 95vw;
    
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: space-around;

    background-color: #000;
    margin: 10px auto;
  }

}
.statistikkPage-dashboard-sub {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
}