.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in-out;
}

.toast-top-right {
  position: fixed;
  top: 70px;
  right: 15px;
  z-index: 9999999;
  min-width: 500px;
  width: 600px;
  span {
    word-wrap: break-word;
  }
}
.alert-dismissible {
  padding-right: 3rem;
}

@media (max-width: $grid-breakpoints-md) {
  .toast-top-right {
    top: 65px;
    right: 5vw;
    z-index: 9999999;
    min-width: 90vw;
    width: 90vw;
  }
  .alert-dismissible {
    padding-right: 3rem;
  }
}
