.avvik-blade {
  width: 100%;
  padding: 15px;
  .status-loading {
    height: 80px;
    margin-bottom: 16px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  h6,
  .searchable-dropdown-list > label {
    font-weight: bold;
  }

  .button-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    .btn:first-child {
      text-align: left;
      svg {
        margin-right: 5px;
      }
    }
    .btn:last-child {
      text-align: right;
      svg {
        margin-left: 5px;
      }
    }
    .btn {
      color: #4d4d4d;
      padding: 0;
    }
    .avvikblade-pdf-btn {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      svg {
        margin-left: 0 !important;
      }
    }
  }

  .item-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    margin-bottom: 24px;

    a,
    .btn-link {
      font-size: 19px;
      font-weight: bold;
      line-height: 3;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .item-body {
    .section {
      margin-bottom: 20px;

      label.form-check-label {
        margin-left: 20px;
      }
    }
    table > tbody > tr > th {
      padding-right: 24px;
    }
    .item-map {
      width: 100%;
      height: 350px;
    }
    .avvik-status-container {
      display: flex;
      justify-content: space-between;

      .avvik-status-badges {
        display: flex;
      }
    }
    .avvik-status-dropdown {
      button {
        width: 100%;
        text-align: left;
        background-color: transparent;
      }

      .dropdown-toggle::after {
        float: right;
        margin-top: 8px;
      }

      .dropdown-menu {
        width: 100%;

        .dropdown-item:hover {
          background-color: #f8f9fa;
        }
      }

      .dropdown-item:active {
        background-color: #f8f9fa;
        color: black;
      }
    }

    .item-images {
      .image-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        img {
          display: block;
          width: 100%;
          height: auto;
          border: 1px solid lightgray;
          margin-bottom: 20px;
        }
      }
    }
    .alvorlighetsgrad-checkbox.form-check {
      padding-left: 0;
    }
  }
  .delete-avvik {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .delete-avvik-btn {
      border: none;
      svg {
        color: #4d4d4d;
      }
      span {
        color: $red-dark;
      }
    }
  }
  .alvorlighetsgrad-checkbox {
    label {
      cursor: not-allowed;
    }
  }
}

textarea.bekreft-ny-status-modal.form-control {
  height: 110px;
}

i.invalid-state {
  color: red;
  font-weight: bold;
  font-size: larger;
}

@media (min-width: 992px) {
  .item-map {
    display: none;
  }
}

.avvik-status-badge {
  margin-left: 5px;
}

.avvik-historikk-expand-button {
  width: 100%;
  text-align: left;
  background-color: #f2f2f2;
  padding: 10px;
  font: inherit;
  border: 0;
}

.avvik-historikk-caret-down::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  background: url(../../images/glyphicons-pil-ned.svg) no-repeat no-repeat
    center center;
  background-size: cover;
}
.avvik-historikk-caret-left::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background: url(../../images/glyphicons-pil-ned.svg) no-repeat no-repeat
    center center;
  background-size: cover;
}

.avvik-historikk-expand-button:active,
.avvik-historikk-expand-button:focus {
  box-shadow: none;
}

.avvik-historikk-element {
  display: flex;
  color: #656564;
  flex-direction: column;
  border-bottom: 1px solid #a4a4a4;
  padding-top: 10px;
  padding-bottom: 10px;

  .avvik-historikk-tittel-button {
    width: 100%;
    text-align: left;
    background-color: transparent;
    font: inherit;
    border: 0;
    padding: 0;
  }

  .avvik-historikk-tittel-button:active,
  .avvik-historikk-tittel-button:focus {
    box-shadow: none;
  }

  .avvik-historikk-rolle-og-tidspunkt,
  .avvik-historikk-element-status {
    display: flex;
    justify-content: space-between;
  }

  .avvik-historikk-element-status {
    padding-top: 10px;
  }

  .avvik-historikk-element-endret-til {
    padding-top: 3px;
  }

  .avvik-historikk-element-kommentar {
    padding-right: 10px;
    padding-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    cursor: pointer;
  }

  .truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .avvik-historikk-element-tidspunkt {
    white-space: nowrap;
    line-height: 1.2;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 2s infinite linear;
}

.text-gray {
  color: grey;
}
