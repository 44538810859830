.landingsside-container {
  padding: 25px;
 
  .react-calendar__month-view__weekdays__weekday {
    abbr[title] {
      text-decoration: none;
      font-weight: bold;
    }
  }

  .landingsside-kalender {
    height: 100%;
    width: 100%;
    background-color: $gray-lightest;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .react-calendar__month-view__days__day--weekend,
    .react-calendar__month-view__days__day--neighboringMonth {
      color: inherit;
    }

    .react-calendar__month-view__days,
    .react-calendar__year-view__months,
    .react-calendar__decade-view__years {
      // .react-calendar__tile--hover {
      //   background-color: $gray-lightest;
      // }
      .react-calendar__tile--active {
        background-color: #9b9b9b;
        color: #fff;
      }
      .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd {
        background-color: $primary;
        color: #fff;
      }
      .react-calendar__tile:disabled {
        background-color: transparent;
        color: #9b9b9b;
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      abbr[title] {
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .react-calendar__navigation {
      margin-bottom: 0;
      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }
      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button,
      .react-calendar__navigation__next-button {
        font-size: 23px;
        font-weight: bold;
      }
      .react-calendar__navigation__label {
        font-size: 14px;
        font-weight: bold;
      }
      button[disabled].react-calendar__navigation__label {
        background-color: transparent;
        color: #212121;
      }
    }

    // .react-calendar__navigation {
    //   margin-bottom: 0.2em;
    //   height: 25px;
    //   > button {
    //     font-weight: bold;
    //   }
    //   .react-calendar__navigation__prev2-button,
    //   .react-calendar__navigation__next2-button {
    //     display: none;
    //   }
    // }
    // .react-calendar__tile {
    //   padding: 0.5em 0.5em;
    // }

    &:hover,
    &:focus {
      background-color: darken($gray-lightest, 10%);
    }
  }

  .box-container {
    display: grid;
    margin-top: 15px;
    grid-gap: 25px;
    grid-template-columns: minmax(210px, 2fr) minmax(245px, 1fr) minmax(245px, 1fr);
    grid-template-rows: repeat(3, auto);

    .header-boks {
      margin-top: auto;
      margin-bottom: auto;
    }
    .varsel-boks {
      grid-column: 2/4;
      grid-row: 1;
      margin-top: auto;
      margin-bottom: auto;
    }

    .boks {
      width: 100%;
      padding: 29px 25px;
      background-color: $gray-lightest;

      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: darken($gray-lightest, 10%);
        .info-div > .info-div-sub {
          background-color: darken($blue-dark, 5%);
        }
      }

      &:active {
        background-color: darken($gray-lightest, 15%);
      }

      p {
        font-size: 19px;
      }

      .info-div {
        justify-self: flex-end;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .info-div-sub {
          font-size: 16px;
          font-weight: bold;
          align-self: flex-start;
          padding: 6px 12px;
          color: #fff;
          background-color: $blue-dark;
          border-color: $blue-dark;
          border-radius: 0.25rem;
        }
      }
    }

    .driftskart-boks {
      position: relative;
      grid-column: 1;
      grid-row: 2 / 4;

      &::before {
        content: '';
        background-image: url('../../images/mappreview.jpg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      &:hover,
      &:focus {
        filter: brightness(85%);
      }
      .info-div {
        justify-content: flex-start;
        z-index: 1000;
        .info-div-sub {
          h2 {
            text-align: left;
            color: #fff !important;
          }
          text-align: left;      
        }
      }
    }
    .registrer-boks {
      grid-column: 2;
      grid-row: 2;
      background-color: $blue-lighter;
      &:hover,
      &:focus {
        background-color: darken($blue-lighter, 10%);
      }

      &:active {
        background-color: darken($blue-lighter, 20%);
      }
    }
    .oversikt-boks {
      grid-column: 3;
      grid-row: 2;
    }
    .kalender-boks {
      grid-column: 2;
      grid-row: 3;
    }
    .statistikk-boks {
      grid-column: 3;
      grid-row: 3;
    }
  }
}

@media (max-width: $grid-breakpoints-md) {
  .landingsside-container {
    margin: 25px 0;
          h1 {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
          font-size: 30px;
        }
      }
.box-container {
      display: flex;
      flex-direction: column;

      .boks {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 17px;
        width: 100%;
        background-color: $blue-dark;
        color: #fff;
        margin: 15px 0;

        h2,
        h4 {
          text-align: center;
          color: #fff !important;

          span {
            font-size: 20px;
            font-weight: 900;
            color: #fff;
          }
        }
        p {
          text-align: center;
          display: block;
        }
        &:hover,
        &:focus {
          background-color: darken($blue-dark, 10%);
          .info-div > .info-div-sub {
            background-color: darken($blue-dark, 5%);
          }
        }

        &:active {
          background-color: darken($blue-dark, 15%);
        }
      }

      .driftskart-boks {
        height: 250px;
        padding: 0;
        .info-div {
          flex-grow: 1;
          z-index: 1000;
          .info-div-sub {
            border-radius: 0;
            width: 100%;
            h2 {
              text-align: center;
              color: #fff !important;
            }
          }
        }
      }
      .registrer-boks {
        .info-div {
          flex-grow: 0;
          display: none;
        }
      }
    }
  }
}
