#helsesjekk-dashboard-container {
  position: absolute;
  top: 0;
  z-index: 1100;
  height: 100%;
  width: 100%;
  padding: 0 20px 20px 20px;
  color: #fff;
  background-color: #343a40;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#helsesjekk-dashboard-container .loader-container {
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#helsesjekk-dashboard-container .loader-container #status {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #f62d51;
  margin: 0 10px;
}

#helsesjekk-dashboard-container .loader-container #status.ok {
  background-color: #55ce63;
}

#helsesjekk-dashboard-container .panel {
  width: 80%;
  background-color: #454d55;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#helsesjekk-dashboard-container .panel.systemstatus {
  min-height: 15%;
  align-items: center;
}

#helsesjekk-dashboard-container .panel.healthy {
  background-color: #55ce63;
}

#helsesjekk-dashboard-container .panel.unhealthy {
  background-color: #f62d51;
}

#helsesjekk-dashboard-container .panel.degraded {
  background-color: #ffbc34;
}

#helsesjekk-dashboard-container .panel div.key {
  font-size: 24px;
}

#helsesjekk-dashboard-container .panel div.value {
  font-size: 16px;
}

#helsesjekk-dashboard-container .panel.systemstatus div.key {
  font-size: 32px;
  font-weight: bold;
}

@media (max-width: 768px) {
  #helsesjekk-dashboard-container .panel {
    width: 100%;
  }

  #helsesjekk-dashboard-container .panel div.key {
    font-size: 16px;
  }

  #helsesjekk-dashboard-container .panel div.value {
    font-size: 14px;
  }

  #helsesjekk-dashboard-container .panel.systemstatus div.key {
    font-size: 16px;
    font-weight: bold;
  }
}
