.leaflet-container {
  height: 100%;
  font: inherit !important;
}

.bym-marker-popup h4 {
  color: #0078a8;
}

.bym-marker-popup h4 a {
  text-decoration: underline;
}

.bym-marker-popup table {
  width: 100%;
}

.bym-marker-popup table td:first-child {
  font-weight: bolder;
  padding: 2px 8px 2px 0;
  vertical-align: top;
}

.bym-marker-popup table td:last-child {
  text-align: right;
}

.sattelite-container {
  position: absolute;
  top: 20px;
  right: 80px;
  z-index: 401;
  border-radius: 5px;
  width: 50px;
  height: 50px;

  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);

  padding: 10px;
  background-color: #ffffff;
}

.sattelite-container > svg {
  width: 30px;
  height: 30px;
}

.leaflet-control-layers {
  .leaflet-control-layers-toggle {
    background-image: url(../../../images/kart-ikon.svg) !important;
  } 
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px !important;
  height: 30px !important;
}
.leaflet-control-layers-list {
  .leaflet-control-layers-separator {
    display: none;
  }
  .leaflet-control-layers-overlays {
    display: none;
  }
}

.driftskart-bound-polygon {
  stroke: #fff;
  stop-opacity: 0;
  stroke-dasharray: 6;
}
.driftskart-bound-polygon-bacground {
  stroke: #000;
  stop-opacity: 0;
}