.map-input-marker {
  .leaflet-popup-content-wrapper {
    border-radius: 3px;
    .leaflet-popup-content {
      margin: 10px 10px;
      .map-input-marker-container {
        display: grid;
        grid-template-columns: 180px 40px;
        grid-template-rows: 60px 60px;
        grid-template-rows: auto;
        .map-input-marker-title {
          grid-column: 1;
          grid-row: 1;
          padding: 5px;
          span {
            font-weight: bold;
          }
        }
        .map-input-marker-content {
          grid-column: 1;
          grid-row: 2;
          padding: 2px;
          display: flex;
          padding: 5px;
          span {
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 4px;
            margin-left: 4px;
            border-left: 1px solid $gray-lighter;
          }
        }
        .map-input-marker-marker-icon {
          grid-column: 2;
          grid-row: 1 / 3;
          justify-self: center;
          padding: 5px;
        }
      }
    }
  }
}

.marker-cluster {
  background-color: #4d4d4d !important;
  border: 6px solid #a3a3a3;
  border-radius: 50%;
  color: #fff;
  height: 40px;
  line-height: 29px;
  text-align: center;
  width: 40px;

  div {
    background-color: transparent !important;
    width: auto !important;
    height: auto !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}
