#page-container {
  // margin-top: $body-margin-top-sm;
  width: 100%;
}

.bym-navbar {
  border-bottom: 1px solid #f6a623;
  background-color: #d8d8d8 !important;
  .navbar-toggler {
    border-color: #ddd;
    margin-right: 15px;
    border: 1px solid transparent;
  }
  .navbar-brand {
    .brand-title {
      color: #4d4c4d;
      font-size: 16px;
      font-weight: 700;
      height: 34px;
    }
    .brand-byvaapen {
      width: 38px;
      margin-left: 15px;
      margin-right: 11px;
    }
  }
  .navbar-collapse {
    .navbar-nav {
      .active {
        background-color: #e7e7e7;
        font-weight: bold;
      }
      .nav-item {
        .nav-link--dropdown-item {
          color: #4d4c4d;
        }
        .nav-link {
          padding: 20px;
          span {
            text-transform: uppercase;
            color: $gray;
            font-size: 16px;
          }
        }
        button {
          text-transform: uppercase;
        }
      }
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  #page-container {
    // margin-top: 64px;
    width: 100%;
  }
  .bym-navbar {
    // min-height: 64px;
    border-bottom: 3px solid #f6a623;
    padding: 0;
    .navbar-brand {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .brand-byvaapen {
        width: 38px;
        margin-left: 15px;
        margin-right: 11px;
      }
      span {
        color: #4d4c4d;
        font-size: 24px;
        font-weight: 700;
      }
    }
    .collapsing {
      // height: 64px !important;
      -webkit-transition: none;
      transition: none;
      display: none;
    }
    .navbar-collapse {
      .navbar-nav {
        // height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        .nav-item {
          border-left: 1px #4d4c4d solid;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .nav-link {
            text-transform: uppercase;
            color: $gray;
            font-size: 16px;
          }
          button {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@media (min-width: $grid-breakpoints-xl) {
  #page-container {
    // margin-top: 67px;
    width: 100%;
  }
}

@media (max-width: 392px) {
  .bym-navbar {
    .navbar-brand {
      .brand-title {
        display: none;
      }
    }
  }
}
