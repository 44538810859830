.statistikkPage-wrapper {
  background-color: #f3f3f3;
  padding: 25px;
}

.statistikkPage-header {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}

.statistikkPage-filter {
  .statistikk-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .statistikk-filter-element {
      margin-left: 10px;
      margin-bottom: 10px;
      min-width: 200px;
    }
  }

  .periodevelger-button[disabled] {
    background-color: hsl(0, 0%, 94%);
    border-color: hsl(0, 0%, 90%);
  }

  .statistikk-gruppe {
    max-width: 325px;
    margin-bottom: 15px;

    .isSelected > div[class$='-control'] {
      background-color: rgb(42, 40, 89);

      div[class$='-singleValue'] {
        color: white;
      }
    }
  }

  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
}

.avvik-og-kontroller-date-picker {
  input {
    background: url(../../images/kalender-ikon.svg) no-repeat 5px 5px;
    background-color: #fff;
    background-position: right 5px center;
    background-size: 20px 20px;
    width: 150px;
  }
}

.statistikkPage-chart-container {
  background-color: white;
  padding: 20px;
  border: 10px solid #f3f3f3;
  //margin-right: 20px;
  //margin-bottom: 20px;
  overflow: hidden;
  min-height: 560px;
}

g.recharts-layer.recharts-cartesian-axis-tick {
  font-size: 10px;
}
